<template>
    <div>
        <div v-if="isMobile"
            class="nav-container font-roboto tracking-ider font-bold text-xs md:text-xs xl:text-base 2xl:text-lg">
            <nav :class="menuOpen ? 'bg-background-3' : ''" class="fixed top-0 z-60 w-full transform ease-in-out transition-all duration-300" style="width: 100vw !important;">
                <div
                    :class="menuOpen ? '' : 'p-2 sm:p-5'"
                    class="flex-auto header-bg w-full flex flex-wrap flex-row-reverse items-center justify-between mx-auto gap-2">
                    <button @click="menuOpen = !menuOpen"
                    :class="menuOpen ? 'py-2.5' : 'bg-opacity-50 bg-black rounded-full py-2 sm:py-4'"
                        class="flex-initial flex-wrap items-center justify-between p-2 sm:p-4 text-white hover:text-secondary-3 font-lg text-sm transform ease-in-out transition-all duration-300"
                        type="button">
                        <img class="w-5 xs:w-6 sm:w-8 sm:h-8 md:w-auto" :src="baseUrl.img + '/assets/img/header/burgermenu.svg'" alt="" />
                    </button>

                    <div
                        v-if="menuOpen"
                        :class="isMobile ? 'sm:pr-4' : 'pr-4 sm:pr-8 md:pr-8 lg:pr-12 2xl:pr-24'"
                        class="flex-1 header-bg w-full flex flex-wrap items-center justify-between mx-auto pl-2 xs:pl-4 sm:pl-8 md:pl-8 lg:px-2">
                        <span @click="enableScroll()">
                            <router-link :to="{ name: 'home' }"
                                class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-22">
                                <img class="w-14 xs:w-24 -translate-y-1 lg:w-30 lg:translate-y-0"
                                    :src="baseUrl.img + '/assets/img/logo.webp'" alt="" />
                            </router-link>
                        </span>
                        <div class="flex items-center space-x-2 sm:space-x-4 xl:space-x-6 2xl:space-x-8">
                            <div class="flex flex-row space-x-1 tracking-widest helveticalight text-secondary-4">
                                <div class="flex items-center">
                                    <div class="flex gap-2 items-center justify-center bg-background-2 rounded-full p-1 sm:p-2 md:relative">
                                        <div @click="setActiveCoinType('gold')"
                                            :class="active_coin_type == 'gold' ? 'bg-background-4 text-white' : 'text-background-3'"
                                            class="rounded-full ml-1 sm:ml-2 cursor-pointer coin-wrapper"
                                        >
                                            <div class="flex items-center justify-center tracking-normal">
                                                <img class="cursor-pointer -ml-1 sm:-ml-2 w-5 xs:w-6 sm:w-8 2xl:w-auto"
                                                    :src="baseUrl.img + '/assets/img/header/GC.svg'" alt="" />
                                                <span class="pl-1 sm:pl-2 pr-2 sm:pr-4 text-mid sm:text-xs md:text-xs xl:text-base 2xl:text-lg">
                                                    {{ active_coin_type != 'gold' ? money(gold_balance) : 'In Use'}}
                                                </span>
                                            </div>
                                            <Tooltip :type="'gold'"/>
                                        </div>
                                        <div @click="setActiveCoinType('silver')"
                                            :class="active_coin_type == 'silver' ? 'bg-background-4 text-white' : 'text-background-3'"
                                            class="mx-2 rounded-full cursor-pointer coin-wrapper"
                                        >
                                            <div class="flex items-center justify-center tracking-normal">
                                                <img class="cursor-pointer -ml-1 sm:-ml-2 w-5 xs:w-6 sm:w-8 2xl:w-auto"
                                                    :src="baseUrl.img + '/assets/img/header/SC.svg'" alt="" />
                                                <span class="pl-1 sm:pl-2 pr-2 sm:pr-4 text-mid sm:text-xs md:text-xs xl:text-base 2xl:text-lg">
                                                    {{ active_coin_type != 'silver' ? money(silver_balance) : 'In Use'}}
                                                </span>
                                            </div>
                                            <Tooltip :type="'silver'"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        <div v-else class="nav-container font-roboto tracking-ider font-bold text-xs md:text-xs xl:text-base 2xl:text-lg">
            <nav class="fixed top-0 z-60 w-full bg-background-3" style="width: 100vw !important;">
                <div
                    class="flex-auto header-bg w-full flex flex-wrap flex-row-reverse lg:flex-row items-center justify-between mx-auto gap-2 bg-background-3">
                    <button @click="menuOpen = !menuOpen"
                        class="hidden lg:block flex-initial flex-wrap items-center justify-between mx-auto p-4 text-white hover:text-secondary-3 font-lg rounded-lg text-sm pl-5 py-2.5"
                        type="button">
                        <img class="w-5 xs:w-6 sm:w-8 sm:h-8 md:w-auto" :src="baseUrl.img + '/assets/img/header/burgermenu.svg'" alt="" />
                    </button>

                    <div
                        class="flex-1 header-bg w-full flex flex-wrap items-center justify-between mx-auto pl-2 xs:pl-4 sm:pl-8 md:pl-8 lg:pl-0 lg:px-2 pr-2 xs:pr-4 sm:pr-8 md:pr-8 lg:pr-12 2xl:pr-24">
                        <span @click="enableScroll()">
                            <router-link :to="{ name: 'home' }"
                                class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-22">
                                <img class="w-14 xs:w-24 -translate-y-1 lg:w-30 lg:translate-y-0"
                                    :src="baseUrl.img + '/assets/img/logo.webp'" alt="" />
                            </router-link>
                        </span>
                        <div class="flex items-center space-x-2 sm:space-x-4 xl:space-x-6 2xl:space-x-8">
                            <div class="hidden lg:block items-center justify-between w-full lg:flex md:w-auto"
                                id="navbar-cta">
                                <div
                                    class="flex items-center p-4 md:p-0 mt-4 space-x-2 sm:space-x-4 xl:space-x-8 2xl:space-x-12 flex-row md:mt-0 md:border-0">
                                    <span @click="enableScroll()">
                                        <router-link :to="{ name: 'home' }">
                                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('home') || isUrl('')
                                                    ? 'text-secondary-3'
                                                    : 'hover:text-secondary-3 text-shade-1'
                                                ">
                                                Home
                                            </div>
                                        </router-link>
                                    </span>
                                    <span @click="enableScroll()">
                                        <router-link :to="{ name: 'games' }">
                                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('games')
                                                    ? 'text-secondary-3'
                                                    : 'hover:text-secondary-3 text-shade-1'
                                                ">
                                                Games
                                            </div>
                                        </router-link>
                                    </span>
                                    <span @click="enableScroll()">
                                        <router-link :to="{ name: 'promotion' }">
                                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('promotion')
                                                    ? 'text-secondary-3'
                                                    : 'hover:text-secondary-3 text-shade-1'
                                                ">
                                                Promotions
                                            </div>
                                        </router-link>
                                    </span>
                                    <span @click="enableScroll()">
                                        <router-link :to="{ name: 'support' }">
                                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('support')
                                                    ? 'text-secondary-3'
                                                    : 'hover:text-secondary-3 text-shade-1'
                                                ">
                                                Support
                                            </div>
                                        </router-link>
                                    </span>
                                    <a @click="open_kyc_navigation('from_redeem')">
                                        <div class="transform ease-in-out transition-all duration-200 cursor-pointer"
                                            :class="isUrl('redeem')
                                                    ? 'text-secondary-3'
                                                    : 'hover:text-secondary-3 text-shade-1'
                                                ">
                                            Redeem
                                        </div>
                                    </a>
                                    <div>
                                        <a class="flex items-center" :id="isMobile ? '' : 'notification-dewacrm'"
                                            @click="isMobile ? goToNotifications() : ''" href="javascript:void(0)">
                                            <div class="flex items-center"
                                                :id="isMobile ? '' : 'notification-icon-dewacrm'">
                                                <img class="cursor-pointer w-5 2xl:w-6"
                                                    :src="baseUrl.img + '/assets/img/header/bell.svg'" alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-1 tracking-widest helveticalight text-secondary-4">
                                <div class="p-1 flex items-center">
                                    <div class="flex gap-2 items-center justify-center bg-background-2 rounded-full p-1 sm:p-2 md:relative">
                                        <div @click="setActiveCoinType('gold')"
                                            :class="active_coin_type == 'gold' ? 'bg-background-4 text-white' : 'text-background-3'"
                                            class="rounded-full ml-1 sm:ml-2 cursor-pointer coin-wrapper"
                                            id="gold-coin-trigger"
                                        >
                                            <div class="flex items-center justify-center tracking-normal">
                                                <img class="cursor-pointer -ml-1 sm:-ml-2 w-5 xs:w-6 sm:w-8 2xl:w-auto"
                                                    :src="baseUrl.img + '/assets/img/header/GC.svg'" alt="" />
                                                <span class="pl-1 sm:pl-2 pr-2 sm:pr-4 text-mid sm:text-xs md:text-xs xl:text-base 2xl:text-lg">
                                                    {{ active_coin_type != 'gold' ? money(gold_balance) : 'In Use'}}
                                                </span>
                                            </div>
                                            <Tooltip :type="'gold'"/>
                                        </div>
                                        <div @click="setActiveCoinType('silver')"
                                            :class="active_coin_type == 'silver' ? 'bg-background-4 text-white' : 'text-background-3'"
                                            class="mx-2 rounded-full cursor-pointer coin-wrapper"
                                            id="silver-coin-trigger"
                                        >
                                            <div class="flex items-center justify-center tracking-normal">
                                                <img class="cursor-pointer -ml-1 sm:-ml-2 w-5 xs:w-6 sm:w-8 2xl:w-auto"
                                                    :src="baseUrl.img + '/assets/img/header/SC.svg'" alt="" />
                                                <span class="pl-1 sm:pl-2 pr-2 sm:pr-4 text-mid sm:text-xs md:text-xs xl:text-base 2xl:text-lg">
                                                    {{ active_coin_type != 'silver' ? money(silver_balance) : 'In Use'}}
                                                </span>
                                            </div>
                                            <Tooltip :type="'silver'"/>
                                        </div>
                                    </div>
                                    <div class="ml-4 hidden lg:block">
                                        <div class="flex items-center">
                                            <div @click="() => open_kyc_navigation('from_get_coins')"
                                                class="rounded-full transition-transform scale-100 hover:scale-105 duration-300 font-roboto font-bold tracking-wider px-6 py-2 bg-secondary-3 text-primary-1 text-xs sm:text-xs md:text-xs lg:text-sm xl:text-lg !cursor-pointer">
                                                <span class="cursor-pointer">Get Coins</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button @click="menuOpen = !menuOpen"
                                class="lg:hidden flex flex-wrap items-center justify-between mx-auto text-white hover:text-secondary-3 transform ease-in-out transition-all duration-200"
                                type="button">
                                <svg class="w-5 xs:w-6 sm:w-8 sm:h-8" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 17 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>


        <!-- drawer component -->
        <div :style="!menuOpen ? 'left:-256px' : ''" id="drawer-navigation"
            class="fixed top-0 left-0 z-60 w-64 h-full text-shade-1 transform ease-in-out transition-all duration-300 play-c-padding"
            tabindex="-1" aria-labelledby="drawer-navigation-label">
            <div class="bg-background-1 h-full overflow-y-auto">
                <div class="px-8 pb-6 pt-10 bg-background-2">
                    <div class="flex flex-col justify-start items-center gap-4">
                        <div class="rounded-full h-11 w-11 bg-shade-4 flex justify-center items-center">
                            <img class="w-auto mx-auto px-1" :src="baseUrl.img + '/assets/img/logo.svg'" alt="" />
                        </div>
                        <div class="flex justify-start flex-col items-left text-left overflow-hidden w-full">
                            <p
                                class="cursor-pointer text-sm leading-5 font-bold text-shade-1 text-nowrap text-ellipsis overflow-hidden">
                                {{ isUser?.username }}
                            </p>
                            <p
                                class="cursor-pointer text-xs leading-3 text-shade-1 text-nowrap text-ellipsis overflow-hidden">
                                {{ isUser?.email }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-start left-nav-height bg-background-1">
                    <div class="flex-1 pt-8 overflow-y-auto w-full custom-scrollbar">
                        <ul class="left-navigation-container space-y-2 font-medium px-8">
                            <li>
                                <a @click="open_kyc_navigation('from_get_coins'), (menuOpen = false)"
                                    class="flex items-center py-2 rounded-lg group cursor-pointer"
                                    :class="isUrl('gold-coin-shop') ? 'active-left-navigation' : ''">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/get-coins-side.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">Get Coins</span>
                                </a>
                            </li>
                            <li @click="enableScroll()">
                                <router-link :to="{ name: 'games' }" class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('games') ? 'active-left-navigation' : ''" @click="menuOpen = false">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/games.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">All Games</span>
                                </router-link>
                            </li>
                            <li @click="enableScroll()">
                                <router-link @click="menuOpen = false" :to="{ name: 'promotion' }"
                                    class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('promotion') ? 'active-left-navigation' : ''">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/promotion.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">Promotions</span>
                                </router-link>
                            </li>
                            <li>
                                <a @click="open_kyc_navigation('from_redeem'), (menuOpen = false)"
                                    class="flex items-center py-2 rounded-lg group cursor-pointer"
                                    :class="isUrl('redeem') ? 'active-left-navigation' : ''">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/redeem.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">Redeem</span>
                                </a>
                            </li>
                            <li @click="enableScroll()">
                                <router-link @click="menuOpen = false" :to="{ name: 'support' }"
                                    class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('support') ? 'active-left-navigation' : ''">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/support.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">Support</span>
                                </router-link>
                            </li>
                            <li>
                                <a @click="openMyAccount(), (menuOpen = false)"
                                    class="flex items-center py-2 rounded-lg group cursor-pointer">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/account.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">My Account</span>
                                </a>
                            </li>
                            <li v-if="isMobile" class="lg:hidden">
                                <a @click="goToNotifications()" class="flex items-center py-2 rounded-lg group cursor-pointer">
                                    <div class="h-8 w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/bell.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold">Notification</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="py-5 w-64 z-60 px-10 text-white cursor-pointer transform ease-in-out transition-all duration-300 text-base">
                        <div class="flex flex-wrap items-center justify center gap-x-2 tracking-widest">
                            <div @click="() => logout()" class="w-full text-center rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer">
                                <span class="cursor-pointer">Logout</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="menuOpen" @click="menuOpen = false" class="fixed inset-0 z-50"
            style="background-color: rgba(0, 0, 0, 0.3)"></div>

        <Kyc v-model:isUser="isUser" />
        <DailyBonus :isClaimed="isClaimed" />
        <SocialMediaBonus />
        <WelcomeBonus />
        <idVerification />
        <Terms />
        <FreeGCTopUp />
        <SessionExpired />
        <Reminder />
        <VerifyEmail/>
    </div>
</template>

<script>
import {
    ref,
    computed,
    watch,
    onMounted,
    onBeforeUnmount,
    reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import eventBus from "plugins/event.js";
import Kyc from "components/modals/kyc.vue";
import DailyBonus from "components/modals/dailyBonus.vue";
import SocialMediaBonus from "components/modals/socialMediaBonus.vue";
import WelcomeBonus from "components/modals/welcomeBonus.vue";
import { authStore } from "store/auth.js";
import idVerification from "components/modals/idVerification.vue";
// import { useBalanceSocketInit } from "composables/use-balance-socket-init.js";
import Terms from "components/modals/terms.vue";
import FreeGCTopUp from "components/modals/freeGCTopUp.vue";
import SessionExpired from "components/modals/sessionExpired.vue";
import Reminder from "components/modals/reminder.vue";
import VerifyEmail from "components/modals/verifyEmail.vue";
import { baseUrl } from "config/services.js";
import { gamesStore } from "store/games";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import _ from "underscore";
import { useToaster } from "composables/use-toaster.js";
import Tooltip from "./component/tooltip.vue";
import { utilitiesStore } from "store/utilities.js";
import device from 'middleware/device.js';
import { useKYC } from 'composables/use-kyc.js';

export default {
    components: {
        Kyc,
        DailyBonus,
        SocialMediaBonus,
        WelcomeBonus,
        idVerification,
        Terms,
        FreeGCTopUp,
        SessionExpired,
        Reminder,
        Tooltip,
        VerifyEmail
    },
    setup() {
        // const { destroySocket } = useBalanceSocketInit();
        const { open_kyc_navigation } = useKYC();

        const useUtilitiesStore = utilitiesStore();

        const menuOpen = ref(false);
        const sidebarOpen = ref(false);

        const route = useRoute();
        const router = useRouter();

        const useAuthStore = authStore();
        const useGamesStore = gamesStore();

        const mountFlag = ref(false);

        // const loadingGold = ref(false);
        // const loadingSilver = ref(false);

        const isMobile = ref(false);

        const isClaimed = ref(false);

        const { money } = useFormatter();
        const { deviceType } = device();

        const { errorToast } = useToaster();

        const apiParams = reactive({
            type: "",
        });

        const isUser = computed(() => {
            return useAuthStore.user;
        });

        const active_coin_type = computed(() => {
            return useAuthStore.active_coin_type || route.params.type;
        });

        const providerData = computed(() => {
            return useGamesStore.provider || null;
        });


        const reloadBalance = async (val1) => {
            if (val1 == "gold") {
                // loadingGold.value = true;
                apiParams.type = "gold_coins";
                try {
                    await useAuthStore.reloadBalance(apiParams);
                    // loadingGold.value = false;
                } catch (error) {
                    console.error("Error gatting balance:", error);
                }
            } else {
                // loadingSilver.value = true;
                apiParams.type = "silver_coins";
                try {
                    await useAuthStore.reloadBalance(apiParams);
                    // loadingSilver.value = false;
                } catch (error) {
                    console.error("Error gatting balance:", error);
                }
            }
        };

        const gold_balance = computed(() => {
            return useAuthStore.gold_balance;
        });

        const silver_balance = computed(() => {
            return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
        });


        const isUrl = (...urls) => {
            let currentUrl = route.path.substr(1);
            if (urls[0] === "") {
                return currentUrl === "";
            }
            return urls.filter((url) => currentUrl.startsWith(url)).length;
        };

        const openDailyBonus = () => {
            eventBus.emit("open:dailybonus");
            router.replace({ query: { modal: "dailybonus" } });
        };

        const openSocialMediaBonus = () => {
            eventBus.emit("open:socialmediabonus");
            router.replace({ query: { modal: "socialmediabonus" } });
        };

        const openMyAccount = () => {
            enableScroll();
            router.push({ path: "/account", query: { tab: "account-details" } });
        };

        const openWelcomeBonus = () => {
            eventBus.emit("open:welcomebonus");
            router.replace({ query: { modal: "welcomebonus" } });
        };

        const onClaimStatus = (data) => {
            isClaimed.value = data;
        };

        const logout = () => {
            useAuthStore.logout().then(() => {
                router.push({ path: "/signin" });
            });
        };

        const myEventHandler = () => {
            if (deviceType == "mobile") {
                isMobile.value = true;
            } else {
                isMobile.value = false;
            }
        };

        const goToNotifications = () => {
            enableScroll();
            window.location.href = "notification";
        };

        const navigateLink = (path) => {
            enableScroll();
            switch (path) {
                case "home":
                    router.push({ path: "/" });
                    break;
                case "games":
                    router.push({ path: "/games" });
                    break;
                case "promotion":
                    router.push({ path: "/promotion" });
                    break;
                case "support":
                    router.push({ path: "/support" });
                    break;
                case "redeem":
                    open_kyc_navigation("from_redeem");
                    break;
                default:
                    break;
            }
        };

        const onLaunch = async (type) => {
            if (isUser.value) {
                if(open_kyc_navigation() == true) {
                     return;
                };

                const filter = _.filter(
                    providerData.value,
                    function (params) {
                        return params.id == route.params.id;
                    }
                );

                if (filter.length == 0) {
                    return errorToast("Provider Didn't Match!", "top-right");
                };

                if(active_coin_type.value != 'gold') {
                    reloadBalance('gold')
                } else {
                    reloadBalance('silver')
                }

                await gameLauncherAPI(
                    { game_slug: route.params.slug, type: type },
                    filter[0]["game_source"]
                )
                    .then(async (res) => {
                        if (res.status) {
                            if (
                                /iPad|iPhone|iPod/.test(
                                    navigator.userAgent
                                )
                            ) {
                                // alert("This is an iOS device.");
                                setTimeout(async () => {
                                    //  window.open(res.url, "_blank");
                                    await useAuthStore.setActiveGameUrl(res.url);
                                    router.push({ name: 'play', params: { slug: route.params.slug, id: route.params.id, launchcode: res.launch_code, type: type } });
                                });
                            } else {
                                await useAuthStore.setActiveGameUrl(res.url);
                                router.push({ name: 'play', params: { slug: route.params.slug, id: route.params.id, launchcode: res.launch_code, type: type } });
                                // window.open(res.url, "_blank");
                            }
                        }
                    })
                    .catch(() => {
                        errorToast("Something went wrong!", "top-right");
                    });
            } else {
                router.push({ name: "login" });
            }
        };

        const setActiveCoinType = (type) => {
            if(active_coin_type.value != type) {
                menuOpen.value = false;
                useAuthStore.setActiveCoinType(type);
                onLaunch(type);
            }
        };

        const enableScroll = () => {
            useUtilitiesStore.enableScroll();
        };

        const hoverCoin = (id, otherTrigger) => {
            const element = document.getElementById(id);
            const tempTrigger = document.getElementById(otherTrigger);

            element.addEventListener("mouseover", () => {
                element.classList.remove('delay');

                if(tempTrigger.classList.contains('show')) {
                    tempTrigger.classList.add('delay')
                    tempTrigger.classList.remove('show')
                }

                element.classList.add('show');
            });

            element.addEventListener("mouseleave", function() {
                element.classList.remove('show');
            });
        }

        onMounted(async () => {
            hoverCoin('gold-coin-trigger', 'silver-coin-trigger');
            hoverCoin('silver-coin-trigger', 'gold-coin-trigger');
            myEventHandler();
            await useGamesStore.getProvider();
        });

        watch(isUser, (newVal) => {
            if (newVal && !mountFlag.value) {
                useAuthStore.initWalletBalance();
                if (route.query.modal == "kyc") {
                    eventBus.emit("open:kyc");
                } else if (route.query.modal == "idverification") {
                    eventBus.emit("open:idverification");
                }
                mountFlag.value = true;
            }
        });

        onBeforeUnmount(() => {
            destroySocket();
        });

        return {
            sidebarOpen,
            menuOpen,
            isUser,
            isUrl,
            open_kyc_navigation,
            openDailyBonus,
            openSocialMediaBonus,
            openMyAccount,
            openWelcomeBonus,
            logout,
            gold_balance,
            silver_balance,
            reloadBalance,
            // loadingGold,
            // loadingSilver,
            isMobile,
            money,
            goToNotifications,
            onClaimStatus,
            isClaimed,
            navigateLink,
            baseUrl,
            setActiveCoinType,
            active_coin_type,
            enableScroll
        };
    },
};
</script>
